body, h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

em {
  background: #0ff;
  font-style: normal;
}

.header {
  min-height: 50px;
  color: #fff;
  background-image: linear-gradient(to right, #8e43e7, #00aeff);
  align-items: center;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  display: flex;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.header-title:after {
  content: " ▸ ";
  padding: 0 .5rem;
}

.header-subtitle {
  font-size: 1.2rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

.searchbox {
  margin-bottom: 2rem;
}

.pagination {
  text-align: center;
  margin: 2rem auto;
}

.ais-Hits-list {
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  gap: 10px;
  display: grid;
}

/*# sourceMappingURL=index.8c895c4a.css.map */
